<script>
import EnrollmentService from '@/services/EnrollmentService'
import SignaturePad from 'signature_pad'
import rg4js from 'raygun4js'
import reloadPageMixin from '@/mixins/reload-page-mixin.js'

export default {
	name: 'ElectronicSignature',
	mixins: [reloadPageMixin],
	data: () => ({
		layout: 'public-layout',
		signaturePad: null,
		submitClicked: false
	}),
	computed: {
		signatureIsEmpty: function () {
			if (!this.signaturePad) return true
			return this.signaturePad.isEmpty()
		},
		submitDisabled: function () {
			return this.signatureIsEmpty || this.submitClicked || this.isEnrollmentComplete
		},
		addSpinner: function () {
			return !this.signatureIsEmpty && this.submitClicked
		},
		isEnrollmentComplete: function () {
			return this.enrollmentService && this.enrollmentService.isComplete()
		}
	},
	activated () {
		this.submitClicked = false
	},
	created: async function () {
		this.enrollmentService = await EnrollmentService.get()
	},
	mounted: function () {
		this.signaturePad = new SignaturePad(this.$refs.signatureCanvas)
	},
	methods: {
		clearSignature: function () {
			this.signaturePad.clear()
		},
		submitSignature: async function () {
			this.submitClicked = true
			try {
				await this.enrollmentService.submitSignature(this.signaturePad.toDataURL())
				await this.enrollmentService.completeEnrollment()
				await this.$router.replace({ name: 'thankYou' })
			} catch (error) {
				await rg4js('send', {
					error: 'Error completing enrollment',
					tags: ['Enrollment not completable']
				})
				await this.$router.push({ name: 'error' })
			}
		},
		cancel: function () {
			this.$router.push({ name: 'termsConditions' })
		}
	}
}
</script>
<template>
	<component :is="layout" class="electronic-signature">
		<div class="electronic-signature__body" id="signature-section">
			<h2 class="electronic-signature__title">{{ $t('signature.title') }}</h2>
			<div class="electronic-signature__wrapper">
				<div v-show="!signatureIsEmpty" class="electronic-signature__clear" @click="clearSignature">
					<font-awesome-icon :icon="['far', 'trash-alt']" size="lg"/>
				</div>
				<canvas ref="signatureCanvas" height="300"/>
			</div>
			<div class="electronic-signature__note">{{ $t('signature.message') }}</div>
			<base-button :disabled="submitDisabled"
				modifiers="accent large set-height margin-y"
				@click="submitSignature">
					<font-awesome-icon :icon="['fas', 'spinner']" class="electronic-signature__spinner" spin v-if="addSpinner" />
				<div v-else>{{ $t('signature.submit') }}</div></base-button>
			<div class="electronic-signature__link" @click="cancel">{{ $t('signature.cancel') }}</div>
		</div>
	</component>
</template>

<style lang="scss" scoped>
.electronic-signature {
	&__body {
		align-items: center;
		background-color: var(--surface-colour);
		border: 1px solid var(--border-variant-light);
		border-radius: var(--border-radius);
		color: var(--on-surface);
		display: flex;
		flex-direction: column;
		padding: 2rem 1rem;
		transition: all 3s ease-in;
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		margin: 1rem 0;
	}
	&__wrapper {
		position: relative;
		canvas {
			background-color: rgba(249, 249, 249, 0.6);
			border-bottom: 1px dashed #333;
		}
	}
	&__clear {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: 30px;
		height: 30px;
		top: 8px;
		right: 8px;
		color:  var(--link-colour);
		cursor: pointer;
	}
	&__note {
		font-size: 0.8rem;
		margin: 0.5rem 0;
	}
	&__link {
		color:  var(--link-colour);
		cursor: pointer;
		filter: brightness(80%);
		font-size: 1.1rem;
		margin-top: 1rem;
	}
	&__spinner {
		position: relative;
		font-size: 1.5rem;
		z-index: 10;
		opacity: 1;
		transition: .2s ease;
	}
}
</style>
